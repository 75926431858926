import React from 'react'
import {Link} from 'gatsby'

class Layout extends React.Component {
    render() {
        const {location, title, children} = this.props
        const rootPath = `${__PATH_PREFIX__}/`
        let header

        if (location.pathname === rootPath) {
            header = (
                <h1>
                    <Link className="link" to={`/`}>
                        {title}
                    </Link>
                </h1>
            )
        } else {
            header = (
                <h3>
                    <Link className="link" to={`/`}>
                        {title}
                    </Link>
                </h3>
            )
        }
        return (
            <div className="reading-pane">
                <header>{header}</header>
                <main>{children}</main>
                <footer className="secondary-text">
                    © 2008 - 2021 GP Gooiker
                </footer>
            </div>
        )
    }
}

export default Layout
